import { Observable } from 'rxjs';
import { AuaApiService } from '../../../service/aua-api.service';
import { ProsApiService } from '../../../service/pros-api.service';
import { OscApiService } from '../../../service/osc-api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../service/aua-api.service";
import * as i2 from "../../../service/pros-api.service";
import * as i3 from "../../../service/osc-api.service";
var QuickSearchService = /** @class */ (function () {
    function QuickSearchService(auaApiService, prosApiService, oscApiService) {
        this.auaApiService = auaApiService;
        this.prosApiService = prosApiService;
        this.oscApiService = oscApiService;
    }
    /**
     * Makes the actual search request depending on type
     * @param term to search for
     * @param searchType decides what service to ask
     */
    QuickSearchService.prototype.searchFor = function (term, searchType) {
        var observable;
        switch (searchType) {
            case 'email':
                observable = this.auaApiService.quickSearchUser(term);
                break;
            case 'subject':
                observable = this.auaApiService.quickSearchUser(term);
                break;
            case 'coupon':
                observable = this.oscApiService.quickSearchCouponCode(term, true);
                break;
        }
        return observable;
    };
    /**
     * Formats coupon search results to template readable format
     * @param couponSearchDetails to format
     */
    QuickSearchService.prototype.formatCouponSearchResponse = function (couponSearchDetails) {
        var formattedResponses = [];
        if (typeof couponSearchDetails !== 'undefined') {
            for (var _i = 0, couponSearchDetails_1 = couponSearchDetails; _i < couponSearchDetails_1.length; _i++) {
                var quickSearchResponse = couponSearchDetails_1[_i];
                for (var _a = 0, _b = quickSearchResponse.coupon_result_details; _a < _b.length; _a++) {
                    var couponSearchResultDetail = _b[_a];
                    formattedResponses.push({
                        label: couponSearchResultDetail.coupon_code,
                        value: couponSearchResultDetail,
                        hint: ''
                    });
                }
            }
        }
        return { coupons: formattedResponses };
    };
    /**
     * Returns a new Observable that emits a different response depending on the provided searchType.
     * @param quickSearchResponse current response from quickSearch
     * @param searchType email | subject | coupon
     */
    QuickSearchService.prototype.getQuickSearchResultObservableFromType = function (quickSearchResponse, searchType) {
        var observable;
        if (searchType === 'email' || searchType === 'subject') {
            observable = new Observable(function (observer) {
                observer.next(quickSearchResponse.user_quick_search_detail_response);
                observer.complete();
            });
        }
        else if (searchType === 'coupon') {
            observable = new Observable(function (observer) {
                console.log('couponSearch response is: ', quickSearchResponse);
                observer.next(quickSearchResponse.coupon_search_details);
                observer.complete();
            });
        }
        return observable;
    };
    /**
     * Stores selection of performed quickSearch
     * @param quickSearchResult selected from dropdown
     * @param searchType email | subject | coupon
     */
    QuickSearchService.prototype.setQuickSearchSelectionItem = function (quickSearchResult, searchType) {
        var resultLabel = '';
        switch (searchType) {
            case 'email':
                resultLabel = quickSearchResult.email;
                break;
            case 'subject':
                resultLabel = quickSearchResult.subject;
                break;
            case 'coupon':
                resultLabel = quickSearchResult.coupon_code;
                break;
        }
        // Store Item in service
        this.quickSearchSelectionItem = {
            searchType: searchType,
            key: resultLabel,
            value: quickSearchResult
        };
        return this.quickSearchSelectionItem;
    };
    QuickSearchService.prototype.getQuickSearchSelectionItem = function () {
        return this.quickSearchSelectionItem;
    };
    QuickSearchService.ngInjectableDef = i0.defineInjectable({ factory: function QuickSearchService_Factory() { return new QuickSearchService(i0.inject(i1.AuaApiService), i0.inject(i2.ProsApiService), i0.inject(i3.OscApiService)); }, token: QuickSearchService, providedIn: "root" });
    return QuickSearchService;
}());
export { QuickSearchService };
