import { Subject } from 'rxjs';
import resultCodes from '../../../app.resultCodes';
import * as i0 from "@angular/core";
var MessengerService = /** @class */ (function () {
    function MessengerService() {
        this.ErrorPopUp$ = new Subject();
        this.Notification$ = new Subject();
    }
    /**
     * Reveals Fullscreen Error Pop up. Can only be closed-
     */
    MessengerService.prototype.sendPopUpError = function (popUpDialogItem) {
        this.ErrorPopUp$.next(popUpDialogItem);
    };
    /**
     * Sends a notification displayed in app-notification component
     *
     * @param resultCode to get message
     * @param context of message
     */
    MessengerService.prototype.sendNotification = function (resultCode, context) {
        var message = resultCodes.getMessage(resultCode);
        var data = {
            action: 'display',
            message: message,
            context: context
        };
        this.Notification$.next(data);
    };
    /**
     * Send clear-action to component
     */
    MessengerService.prototype.clearNotifications = function () {
        this.Notification$.next({ action: 'clear' });
    };
    /**
     * Sends Global Response to pop up
     * @param globalResponse with messages
     */
    MessengerService.prototype.handleGlobalResponse = function (globalResponse) {
        var state = globalResponse.result_state;
        switch (state) {
            case 'ERROR':
                var message = resultCodes.getMessage(globalResponse.result_code);
                var detailMessage = globalResponse.result_message;
                // this.sendPopUpError({message, detailMessage});
                break;
        }
    };
    MessengerService.ngInjectableDef = i0.defineInjectable({ factory: function MessengerService_Factory() { return new MessengerService(); }, token: MessengerService, providedIn: "root" });
    return MessengerService;
}());
export { MessengerService };
