import {Injectable} from '@angular/core';
import {Observable, ObservableInput} from 'rxjs';
import {AuaApiService} from '../../../service/aua-api.service';
import {ProsApiService} from '../../../service/pros-api.service';
import {UserQuickSearchDetailResponse} from '../../api/aua_angular-client';
import {QuickSearchSelectionItem} from '../model/QuickSearchSelectionItem';
import {OscApiService} from '../../../service/osc-api.service';
import {CouponSearchDetail1} from '../../api/osc_angular-client';

@Injectable({
    providedIn: 'root'
})
export class QuickSearchService {

    quickSearchSelectionItem: QuickSearchSelectionItem;

    constructor(
        private auaApiService: AuaApiService,
        private prosApiService: ProsApiService,
        private oscApiService: OscApiService
    ) {
    }

    /**
     * Makes the actual search request depending on type
     * @param term to search for
     * @param searchType decides what service to ask
     */
    searchFor(term: ObservableInput<{}>, searchType: string): Observable<any> {
        let observable: Observable<any>;

        switch (searchType) {
            case 'email':
                observable = this.auaApiService.quickSearchUser(term);
                break;
            case 'subject':
                observable = this.auaApiService.quickSearchUser(term);
                break;
            case 'coupon':
                observable = this.oscApiService.quickSearchCouponCode(term, true);
                break;
        }

        return observable;
    }

    /**
     * Formats coupon search results to template readable format
     * @param couponSearchDetails to format
     */
    formatCouponSearchResponse(couponSearchDetails: CouponSearchDetail1[]) {

        const formattedResponses = [];

        if (typeof couponSearchDetails !== 'undefined') {
            for (const quickSearchResponse of couponSearchDetails) {
                for (const couponSearchResultDetail of quickSearchResponse.coupon_result_details) {
                    formattedResponses.push({
                        label: couponSearchResultDetail.coupon_code,
                        value: couponSearchResultDetail,
                        hint: ''
                    });
                }
            }
        }

        return {coupons: formattedResponses};
    }

    /**
     * Returns a new Observable that emits a different response depending on the provided searchType.
     * @param quickSearchResponse current response from quickSearch
     * @param searchType email | subject | coupon
     */
    getQuickSearchResultObservableFromType(quickSearchResponse, searchType: string): Observable<UserQuickSearchDetailResponse | any> {

        let observable: Observable<UserQuickSearchDetailResponse | any>;

        if (searchType === 'email' || searchType === 'subject') {
            observable = new Observable<UserQuickSearchDetailResponse>(
                (observer) => {
                    observer.next(quickSearchResponse.user_quick_search_detail_response);
                    observer.complete();
                }
            );
        } else if (searchType === 'coupon') {
            observable = new Observable<any>(
                (observer) => {
                    console.log('couponSearch response is: ', quickSearchResponse);
                    observer.next(quickSearchResponse.coupon_search_details);
                    observer.complete();
                }
            );
        }

        return observable;
    }

    /**
     * Stores selection of performed quickSearch
     * @param quickSearchResult selected from dropdown
     * @param searchType email | subject | coupon
     */
    setQuickSearchSelectionItem(quickSearchResult: any, searchType: string): QuickSearchSelectionItem {
        let resultLabel = '';

        switch (searchType) {
            case 'email':
                resultLabel = quickSearchResult.email;
                break;
            case 'subject':
                resultLabel = quickSearchResult.subject;
                break;
            case 'coupon':
                resultLabel = quickSearchResult.coupon_code;
                break;
        }

        // Store Item in service
        this.quickSearchSelectionItem = {
            searchType,
            key: resultLabel,
            value: quickSearchResult
        };

        return this.quickSearchSelectionItem;
    }

    getQuickSearchSelectionItem() {
        return this.quickSearchSelectionItem;
    }
}
